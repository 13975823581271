import { AccessLevel } from '@/modules/common/enums/access-levels.enum'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Notification from '@/modules/common/services/notification.service'
import SecurityContext from '@/modules/common/services/security-context.service'
import Container from 'typedi'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component({
  name: 'GtrNewAdminUserForm'
})
export default class GtrNewAdminUserForm extends GtrSuper {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean|undefined

    data () {
      return {
        value: false,
        submitting: false,
        accessLevels: [],
        user: {
          name: null,
          email: null,
          level: null,
          two_factor_enabled: null,
          two_factor_method: null,
          two_factor_phone: null
        },
        two_factor_options: [
          { value: 0, text: 'No' },
          { value: 1, text: 'Yes' }
        ]
      }
    }

    accessLevelActions (value) {
      if (value === 'SUPER_ADMIN') {
        this.$data.user.two_factor_enabled = 1
        this.$data.two_factor_options = [
          { value: 1, text: 'Yes' }
        ]
      } else {
        this.$data.user.two_factor_enabled = null
        this.$data.two_factor_options = [
          { value: 0, text: 'No' },
          { value: 1, text: 'Yes' }
        ]
      }
    }

    onSMSChange () {
      if (!this.$data.user.two_factor_enabled) {
        this.$data.user.two_factor_method = null
        this.$data.user.two_factor_phone = null
      }
    }

    @Watch('visible', { immediate: true })
    onVisibiltyChange (payload: any) {
      this.$data.value = payload
    }

    async mounted () {
      const securityContext = JSON.parse(Container.get(SecurityContext).context())
      const allAccessLevels = await this.getAllAccessLevels()

      if (securityContext) {
        const user = securityContext.user
        switch (user.access_level) {
          case AccessLevel.SUPER_ADMIN: {
            this.$data.accessLevels = allAccessLevels
            break
          }
          case AccessLevel.RESELLER_ADMIN: {
            this.$data.accessLevels = [
              { value: AccessLevel.RESELLER_ADMIN, text: 'Reseller' },
              { value: AccessLevel.COMPANY_ADMIN, text: 'Company Admin' },
              { value: AccessLevel.COMPANY_USER, text: 'Company User' }]
            break
          }
          case AccessLevel.COMPANY_ADMIN: {
            this.$data.accessLevels = [
              { value: AccessLevel.COMPANY_ADMIN, text: 'Company Admin' },
              { value: AccessLevel.COMPANY_USER, text: 'Company User' }]
            break
          }
          case AccessLevel.COMPANY_USER: {
            this.$data.accessLevels = [
              { value: AccessLevel.COMPANY_USER, text: 'Company User' }]
            break
          }
          default: {
            break
          }
        }
      }
    }

    private async getAllAccessLevels () {
      const response = await this.$store.dispatch('company/fetchUserGroups')
      const allAccessLevels: any[] = []
      for (const userGroupKey in response.data) {
        const userGroup = response.data[userGroupKey]
        allAccessLevels.push({ value: userGroup.name, text: userGroup.name })
      }
      return allAccessLevels
    }

    onClose () {
      this.$data.user = {
        name: null,
        email: null,
        level: null,
        two_factor_enabled: null,
        two_factor_method: null,
        two_factor_phone: null
      }
      const observer = (this.$refs.observerForm as any)
      observer.reset()
      this.$data.value = false
      this.$emit('close')
    }

    async submit () {
      try {
        this.$data.submitting = true
        const payload: any = {
          data: {
            name: this.$data.user.name,
            email: this.$data.user.email,
            access_level: this.$data.user.level,
            admin_base_url: window.location.origin,
            two_factor_enabled: this.$data.user.two_factor_enabled,
            two_factor_method: this.$data.user.two_factor_method,
            two_factor_phone: this.$data.user.two_factor_phone
          }
        }
        await this.$store.dispatch('adminUser/createAdminResellerUser', payload)
        await this.$store.dispatch('adminUser/fetchAdminUsersInactive')
        Container.get(Notification).success('User successfully created.')
        this.$data.user = {
          name: null,
          email: null,
          level: null
        }
        this.onClose()
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }
}
