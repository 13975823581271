import { AccessLevel } from '@/modules/common/enums/access-levels.enum'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Notification from '@/modules/common/services/notification.service'
import SecurityContext from '@/modules/common/services/security-context.service'
import { Container } from 'typedi'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  name: 'GtrEditAdminUserForm',
  computed: {
    ...mapState('company', ['companies', 'user_groups'])
  }
})
export default class GtrEditAdminUserForm extends GtrSuper {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean | undefined

    @Prop({
      required: true,
      type: Object,
      default: {
        name: null,
        email: null,
        access_level: null,
        password: null,
        password_confirmation: null,
        active: null,
        two_factor_enabled: null,
        two_factor_method: null,
        two_factor_phone: null
      }
    })
    user: any

    data () {
      return {
        loading: false,
        value: false,
        submitting: false,
        accessLevels: [],
        _user: {},
        _companies: [],
        two_factor_options: [
          { value: 0, text: 'No' },
          { value: 1, text: 'Yes' }
        ],
        companySupportContact: false
      }
    }

    @Watch('visible', { immediate: true })
    onVisibiltyChange (payload: any) {
      this.$data.value = payload
    }

    @Watch('companies')
    onCompaniesChange (payload: any) {
      this.$data._companies = []
      if (payload.length > 0) {
        payload.map((company: any) => {
          this.$data._companies.push({
            value: company.uuid,
            text: company.name,
            logo: company.logo
          })
        })
      }
    }

    onSMSChange () {
      if (!this.$data._user.two_factor_enabled) {
        this.$data._user.two_factor_method = null
        this.$data._user.two_factor_phone = null
      }
    }

    async mounted () {
      this.$data.loading = true
      const allAccessLevels = await this.getAllAccessLevels()

      this.$data._user = this._.cloneDeep(this.user)
      if (this.$data._user) {
        if (this.$data._user.appointed_support_contact === 0) {
          this.$data.companySupportContact = false
        } else if (this.$data._user.appointed_support_contact === 1) {
          this.$data.companySupportContact = true
        }
      }
      if (this.$data._user.company) {
        this.$data._user.company_uuid = this.$data._user.company.uuid
      }
      const securityContext = JSON.parse(Container.get(SecurityContext).context())
      if (securityContext) {
        const user = securityContext.user
        switch (user.access_level) {
          case AccessLevel.SUPER_ADMIN: {
            this.$data.accessLevels = allAccessLevels
            break
          }
          case AccessLevel.RESELLER_ADMIN: {
            this.$data.accessLevels = [
              { value: AccessLevel.RESELLER_ADMIN, text: 'Reseller' },
              { value: AccessLevel.COMPANY_ADMIN, text: 'Company Admin' },
              { value: AccessLevel.COMPANY_USER, text: 'Company User' }]
            break
          }
          case AccessLevel.COMPANY_ADMIN: {
            this.$data.accessLevels = [
              { value: AccessLevel.COMPANY_ADMIN, text: 'Company Admin' },
              { value: AccessLevel.COMPANY_USER, text: 'Company User' }]
            break
          }
          case AccessLevel.COMPANY_USER: {
            this.$data.accessLevels = [
              { value: AccessLevel.COMPANY_USER, text: 'Company User' }]
            break
          }
          default: {
            break
          }
        }
      }
      const companies = this.$store.state.company.companies
      this.$data._companies = []
      if (companies.length > 0) {
        companies.map((company: any) => {
          this.$data._companies.push({
            value: company.uuid,
            text: company.name,
            logo: company.logo
          })
        })
      } else {
        try {
          await this.$store.dispatch('company/fetchCompanies')
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        }
      }
      this.$data.loading = false
    }

    private async getAllAccessLevels () {
      const response = await this.$store.dispatch('company/fetchUserGroups')
      const allAccessLevels: any[] = []
      for (const userGroupKey in response.data) {
        const userGroup = response.data[userGroupKey]
        allAccessLevels.push({ value: userGroup.name, text: userGroup.name })
      }
      return allAccessLevels
    }

    accessLevelActions (value) {
      if (value === 'SUPER_ADMIN') {
        this.$data._user.two_factor_enabled = 1
        this.$data.two_factor_options = [
          { value: 1, text: 'Yes' }
        ]
      } else {
        this.$data._user.two_factor_enabled = null
        this.$data.two_factor_options = [
          { value: 0, text: 'No' },
          { value: 1, text: 'Yes' }
        ]
      }
    }

    onClose () {
      this.$data.value = false
      this.$emit('close')
    }

    async submit () {
      const form = (this.$refs.editUserForm as HTMLFormElement)
      if (form.validate()) {
        try {
          this.$data.submitting = true
          const payload: any = {
            user_uuid: this.$data._user.uuid,
            data: {
              name: this.$data._user.name,
              email: this.$data._user.email,
              access_level: this.$data._user.access_level,
              active: this.$data._user.active === 1,
              company_uuid: this.$data._user.company_uuid,
              two_factor_enabled: this.$data._user.two_factor_enabled,
              two_factor_method: this.$data._user.two_factor_method,
              two_factor_phone: this.$data._user.two_factor_phone,
              appointed_support_contact: this.$data._user.appointed_support_contact
            }
          }
          if (this.$data._user.password) {
            payload.data.password = this.$data._user.password
          }
          await this.$store.dispatch('adminUser/updateUser', payload)
          await this.$store.dispatch('adminUser/fetchAdminUsersInactive')
          Container.get(Notification).success('User successfully updated.')
          this.$data._user = {
            name: null,
            email: null,
            access_level: null,
            password: null,
            password_confirmation: null,
            two_factor_enabled: null,
            two_factor_method: null,
            two_factor_phone: null,
            active: null,
            appointed_support_contact: null
          }
          const observer = (this.$refs.observerForm as any)
          observer.reset()
          this.onClose()
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        } finally {
          this.$data.submitting = false
        }
      }
    }

    private updateUserSupportContact () {
      if (!this.$data.companySupportContact) {
        this.$data._user.appointed_support_contact = 0
      } else if (this.$data.companySupportContact) {
        this.$data._user.appointed_support_contact = 1
      }
    }
}
